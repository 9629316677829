import React, { Fragment, useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import palette from '../../../../styles/palette';

function NativeSelectUI (props) {
    const {
        disabled,
        disableUnderline,
        styles,
        value,
        onChange,
        items,
        color
    } = props;

    const [selectedText, setSelectedText] = useState(items[items.findIndex(x => x.value === value)].label);
    const [width, setWidth] = useState(0);
    const spanRef = useRef(null);

    const useStyles = makeStyles({
        root: {
            width: width,
            color: color ? color : palette.white,
        }
    });
    const useCustom = makeStyles({
        labelSpan: {
            lineHeight: 0,
            height: 0,
            overflow: 'hidden',
            display: 'inline-block',
            position: 'fixed',
            top: '-1000px',
            left: '-1000px',
            fontSize: '1rem'
        }
    });

    const classes = useStyles();
    const customStyles = useCustom();

    useEffect(() => {
        setTimeout(() => {
            if(spanRef.current)
                setWidth(spanRef.current.offsetWidth);
        }, 450);
    }, []);

    useEffect(() => {
        if(spanRef.current)
            setWidth(spanRef.current.offsetWidth);
    }, [selectedText]);

    useEffect(() => {
        if(spanRef.current){
            setSelectedText(items[items.findIndex(x => x.value === value)].label);
            setWidth(spanRef.current.offsetWidth);
        }
    }, [items]);

    const handleOnChange = (event) => {
        const value = event.target.value;
        setSelectedText(items[items.findIndex(x => x.value === value)].label);
        onChange(value);
    };

    return (
        <Fragment>
            <div className={ customStyles.labelSpan } ref={ spanRef }>{ selectedText }</div>
            <NativeSelect
                disabled={ disabled }
                disableUnderline={ disableUnderline }
                value={ value }
                onChange={ handleOnChange }
                classes={{ ...styles, ...classes }}
            >
                { items.map(({ label, value }) => (
                    <option
                        key={ value }
                        value={ value }
                    >
                        { label }
                    </option>
                )) }
            </NativeSelect>
        </Fragment>
    );
}

export default NativeSelectUI;
