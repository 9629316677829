import React, { useContext, useEffect, useState, useRef } from 'react';

import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { useSnackbar } from 'notistack';

import { API } from '../../lib/api';

import config from '../../config/config';

import { LangContext } from '../LangProvider';

import SupportContext from './context';

import SupportNotification from '../../components/supportNotification';

const SupportProvider = ({ children }) => {
  const [unreadMessages, setUnreadMessages] = useState(0);
  const currentListeningTicket = useRef(null);

  const { t } = useContext(LangContext);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    API.getSupportUnreadMessages().then(response => {
      setUnreadMessages(response || 0);
    });

    connectSockets();
  }, []);

  const connectSockets = () => {
    window.Pusher = Pusher;

    window.Echo = new Echo({
      broadcaster: 'pusher',
      authEndpoint: config.socketAuthHost,
      key: config.socketKey,
      cluster: 'cluster',
      wsHost: config.socketWsHost,
      wsPort: 6001,
      wssPort: 6001,
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
      auth: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        }
      }
    });

    window.Echo.private(`support-ticket-message.client.${window.localStorage.getItem('client_code')}`).listen('SupportMessageCreated', data => {
      setUnreadMessages(data.data.unread);
      showSnackbar(data.data);
    });
  };

  const updateUnreadMessagesCount = (ticketId) => {
    API.getSupportUnreadMessages(ticketId).then(response => {
      setUnreadMessages(response || 0);
    });
  };

  const handleChangeCurrentListeningTicket = (ticketId) => {
    currentListeningTicket.current = ticketId;
  };

  const showSnackbar = (data) => {
    if (data.ticket.id !== +currentListeningTicket.current) {
      enqueueSnackbar(t.you_received_message, {
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom"
        },
        content: (key, message) => (
          <SupportNotification
            id={key}
            message={message}
            data={data}
          />
        ),
      });
    }
  };

  return (
    <SupportContext.Provider value={{ unreadMessages, updateUnreadMessagesCount, handleChangeCurrentListeningTicket }}>
      {children}
    </SupportContext.Provider>
  );
};

export default SupportProvider;
