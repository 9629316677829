import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import iconsTime from '../../../../images/icons-time.png';
import { LangContext } from '../../../../providers/LangProvider';
import palette from '../../../../styles/palette';

const useStyles = makeStyles({
    localTimeTextContainer: {
    },
    localTimeTextBlock: {
        fontSize: '14px',
        color: palette.grayChateau,
        paddingLeft: '44px',
        position: 'relative',
        bottom: '17px'
    },
    localTimeIcon: {
        width: '16px',
        height: '16px',
        backgroundImage: `url(${ iconsTime })`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition:  'bottom left',
        backgroundSize: '16px 16px',
        marginLeft: '24px',
        paddingTop: '16px'
    }
});

export default function LocalTimeText() {
    const classes = useStyles();
    const { t } = useContext( LangContext );

    return(
        <Box className={ classes.localTimeTextContainer }>
            <Box className={ classes.localTimeIcon }>
            </Box>
            <Box className={ classes.localTimeTextBlock }>
                { t.everywhere_local_time }
            </Box>
        </Box>
    );
}