import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { StyledButton } from '../../../../styles/common';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LangContext } from '../../../../providers/LangProvider';
import palette from '../../../../styles/palette';

function SubmitButton (props) {
    const { t } = useContext( LangContext );
    const { isLoading, disabled } = props;

    const useStyles = makeStyles({
        buttonLoading: {
            width: '111px',
            height: '40px',
            borderRadius: '4px',
            backgroundColor: palette.sandyBrown,
            color: palette.white,
            fontSize: '16px',
            fontWeight: '500',
            position: 'relative',
            textAlign: 'center'
        },
        buttonProgress: {
            position: 'relative',
            bottom: '23px'
        },
        buttonLoadingLabel: {
            paddingTop: '10px'
        }
    });

    const classes = useStyles();

    return(
        <Fragment>
            { !isLoading ?
                <StyledButton
                    type='submit'
                    widthSize='112px'
                    heightSize='40px'
                    fontSize='16px'
                    disabled={ disabled }
                >
                    { t.save }
                </StyledButton>
                :
                <Fragment>
                    <Box className={ classes.buttonLoading }>
                        <Box className={ classes.buttonLoadingLabel }>
                            { t.save }
                        </Box>
                        <CircularProgress
                            style={{ color: palette.burningOrange }}
                            size={ 24 }
                            className={ classes.buttonProgress }
                        />
                    </Box>
                </Fragment>
            }
        </Fragment>
    )
}

export default SubmitButton;