import styled from 'styled-components'
import arrowCompare from '../../images/arrow-m-compare.png';
import arrowRight from '../../images/arrow-right.png';
import loader from '../../images/loader.gif';
import palette from '../palette';

export const LoadingContainer = styled.div`
    background-color: ${palette.white};
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

export const Arrow = styled.div`
    width: 32px;
    height: 32px;
    margin-bottom: -8px;
    background-color: ${palette.white};
    background-repeat: no-repeat;
    background-position:  center center;
    background-size: 32px;
    border-radius: 4px;
    border: none;
    display: inline-block;
`;

export const ArrowCompare = styled(Arrow)`
    background-image: url(${arrowCompare});
`;

export const ArrowRight = styled(Arrow)`
    background-image: url(${arrowRight});
`;

export const TitleBlock = styled.div`
    margin-bottom: 12px;
    text-align: center;
`;

export const FlightTypeTitle = styled.div`
    color: ${palette.mineShaft};
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 24px;
`;

export const OwTitle = styled(FlightTypeTitle)`
    display: flex;
    justify-content: center;
`;

export const CfTitle = styled(FlightTypeTitle)`
`;

export const RtTitle = styled(FlightTypeTitle)`
`;

export const LoadingSecondText = styled.div`
    color: ${palette.mineShaft};
    font-size: 16px;
`;

export const InfoBlock = styled.div`
    color: ${palette.mineShaft};
    font-size: 18px;
`;

export const LoadingSniperBlock = styled.div`
    position: relative;
    width: 100%;
    height: 100px;
    text-align: center;
    padding-top: 60px;
`;

export const LoadingSniper = styled.div`
    width: 80px;
    height: 80px;
    background-image: url(${loader});
    background-repeat: no-repeat;
    background-position:  center center;
    background-size: 80px;
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
`;

export const PassData = styled.div`
`;

export const TitleItem = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    margin-left: 8px;
`;




