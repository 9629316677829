import {
    SET_PRE_BOOK_DATA, SET_COUNTRIES, SET_PASSENGERS_LIST,
    SET_PASSENGERS_LIST_FILTER, SET_PRE_BOOK_OPTIONS, SET_PRE_BOOK_SELECTED_OPTIONS
} from './constants';
import initialState from './initialState';

export const setPreBookData = (state = initialState, action) => {
    switch (action.type) {
        case SET_COUNTRIES:
            return {
                ...state,
                countriesItems: action.payload
            };
        case SET_PASSENGERS_LIST:
            return {
                ...state,
                passengersList: action.payload
            };
        case SET_PASSENGERS_LIST_FILTER:
            return {
                ...state,
                filterString: action.payload.filterString,
            };
        case SET_PRE_BOOK_DATA:
            return {
                ...state,
                routes: action.payload.routes,
                included: action.payload.included,
                recId: action.payload.recId,
                totalPrice: action.payload.totalPrice,
                partnerFees: action.payload.partnerFees,
                fareRules: action.payload.fareRules,
                isLoad: action.payload.loadStatus,
                flightType: action.payload.flightType,
                configName: action.payload.configName,
                fees: action.payload.fees,
                fare: action.payload.fare,
                taxes: action.payload.taxes,
            };
        case SET_PRE_BOOK_OPTIONS:
            return {
                ...state,
                options: action.payload
            };
        case SET_PRE_BOOK_SELECTED_OPTIONS:
            return {
                ...state,
                selectedOptions: action.payload
            };
        default: return state;
    }
};
