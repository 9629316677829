import { makeStyles } from '@material-ui/core/styles';
import palette from '../../styles/palette';

export const useStyles = makeStyles({
    rootContainer: {
        padding: '24px 0 64px',
        width: '100%'
    },
    mainBlock: {
        padding: '20px 0 20px 24px',
        backgroundColor: palette.white,
        borderRadius: '8px'
    },
    mainBlockItem: {
        width: '100%'
    },
    paperTable: {
        width: '100%',
        borderRadius: '8px',
        backgroundColor: palette.white
    },
    headTableRow: {
        borderBottom: `3px solid ${palette.porcelain}`,
    },
    tableRow: {
        cursor: 'pointer',

        '&$selected, &$selected:hover': {
            backgroundColor: `${palette.linen} !important`
        }
    },
    disabledTableRow: {
        cursor: 'default',
        backgroundColor: palette.porcelainLight
    },
    tableContainer: {
        maxHeight: '600px'
    },
    titleBlock: {
        paddingLeft: '32px',
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        cursor: 'pointer',
        right: '3%'
    },
    title: {
        fontSize: '24px',
        fontWeight: '500',
    },
    buttonProgressSaving: {
        position: 'absolute',
        top: '20%',
        left: '40%'
    },
    userContainer: {
        paddingLeft: '8px',
    },
    userFieldItem: {
        width: '100%',
        minHeight: '86px'
    },
    submitBlock: {
        display: 'flex'
    },
    cancelButton: {
        height: '40px',
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: 500,
        textTransform: 'none',
        backgroundColor: palette.athensGrey,
        '&:active': {
            backgroundColor: palette.geyser
        },
        '&:disabled': {
            backgroundColor: palette.porcelainLight,
            color: palette.submarine
        },
    },
    cancelButtonTitle: {
        paddingLeft: '8px',
        paddingRight: '8px'
    },
    submitButtonBlock: {
        marginLeft: '12px'
    },
    removeButtonBlock: {
        position: 'relative'
    },
    buttonProgressRemoving: {
        position: 'absolute',
        top: '20%',
        left: '42%'
    },
    optionsBlock: {
        marginBottom: '24px'
    },
    searchFieldInput: {
        height: '48px'
    },
    searchIcon: {
        paddingLeft: '8px',
        color: palette.submarine
    },
    buttonsBlock: {
        display: 'flex'
    },
    searchSelectFieldBlock: {
        paddingLeft: '8px'
    },
    checkIcon: {
        color: palette.fruitSalad
    },
    clearIcon: {
        color: palette.tallPoppy
    },
    selected: {}
});