import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import _ from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import palette from '../../../../styles/palette';

export default function RadioButtonGroup(props) {
    const { items, onChange, value, disabled } = props;

    const handleChange = (event) => {
        onChange(event.target.value);
    };

    const CustomRadio = withStyles({
        root: {
            color: palette.burningOrange,

            '&$checked': {
                color: palette.burningOrange,
            },

            '&$disabled': {
                color: palette.geyser,
            },

            '&:hover': {
                color: palette.thunderbird,
            },
        },
        disabled: {},
        checked: {},
    })((props) => <Radio disableRipple {...props} />);

    return (
        <FormControl>
            <RadioGroup
                value={ value }
                onChange={ handleChange }
            >
                { _.map(items, function (item, key) {
                    return  <FormControlLabel
                        key={ key }
                        value={ item.value }
                        control={ <CustomRadio/>}
                        label={ item.label }
                        disabled={ disabled }
                    />
                })
                }
            </RadioGroup>
        </FormControl>
    );
}