'use strict';

import React, { Component, Suspense, lazy } from 'react';
import LoginContainer from './containers/auth/login/index';
import ActivationUserContainer from './containers/auth/activation/index';
import PageNotFound from './components/notFoundPage';
import SearchContainer from './containers/search/index';
import { Route, Switch } from 'react-router-dom';
import { checkAccessForB2B } from './hoc/checkAccessForB2B';
import { withAuthRedirect } from './hoc/withAuthRedirect';
import { withNavTabsForContractSettings } from './hoc/withNavTabsForContractSettings';
import LoadingForBlock from './containers/loadingForms/loadingForBlock';
import Config from './config/config';
import { AuthRoute } from './hoc/AuthRoute';
import { staticPages } from './hoc/staticPages';

const nonAuthRoutes = Config.NonAuthRoutes();
const authRoutes = Config.AuthRoutes();
const userRoles = Config.UserRoles();

const ResultContainer = lazy(() => import('./containers/result/index'));
const BookingContainer = lazy(() => import('./containers/booking/index'));
const OrderContainer = lazy(() => import('./containers/order/index'));
const OrdersContainer = lazy(() => import('./containers/orders/index'));
const PersonalInfo = lazy(() => import('./containers/personalInfo/index'));
const PartnerSettings = lazy(() => import('./containers/partnerSettings/index'));
const Passengers = lazy(() => import('./containers/passengers/index'));
const Users = lazy(() => import('./containers/users/index'));
const Companies = lazy(() => import('./containers/companies/index'));
const MainInfoPage = lazy(() => import('./containers/staticInfo/mainInfoPage/index'));
const BalanceMovements = lazy(() => import('./containers/balanceMovements/index'));
const PopularDestinations = lazy(() => import('./components/staticInfo/popularDestinations'));
const ContactsPage = lazy(() => import('./components/staticInfo/contactsPage'))
const TheyTrustUs = lazy(() => import('./components/staticInfo/theyTrustUs'));
const Support = lazy(() => import('./containers/support/index'));
const SupportTicket = lazy(() => import('./containers/supportTicket/index'));

class Routes extends Component {
    render() {
        return (
            <Suspense fallback={ <LoadingForBlock /> }>
                <Switch>
                    <Route
                        exact
                        path={ nonAuthRoutes.login }
                        component={ LoginContainer }
                    />
                    <Route
                        exact
                        path={ nonAuthRoutes.activationUser }
                        component={ ActivationUserContainer }
                    />
                    <Route
                        exact
                        path={ nonAuthRoutes.popularDestinations }
                        component={ staticPages(PopularDestinations) }
                    />
                    <Route
                        exact
                        path={ nonAuthRoutes.theyTrustUs }
                        component={ staticPages(TheyTrustUs) }
                    />
                    <Route 
                        exact
                        path={ nonAuthRoutes.contactsPage }
                        component={ staticPages(ContactsPage) }
                    />
                    <AuthRoute
                        exact
                        path={ authRoutes.result }
                        requiredRoles={[
                            userRoles.admin,
                            userRoles.manager,
                            userRoles.user_agent,
                            userRoles.user
                        ]}
                        Component={
                            withAuthRedirect(ResultContainer)
                        }
                    />
                    <AuthRoute
                        exact
                        path={ authRoutes.booking }
                        requiredRoles={[
                            userRoles.admin,
                            userRoles.manager,
                            userRoles.user_agent,
                            userRoles.user
                        ]}
                        Component={
                            withAuthRedirect(BookingContainer)
                        }
                    />
                    <AuthRoute
                        exact
                        path={ authRoutes.order }
                        requiredRoles={[
                            userRoles.admin,
                            userRoles.manager,
                            userRoles.user_agent,
                            userRoles.user
                        ]}
                        Component={
                            withAuthRedirect(OrderContainer)
                        }
                    />
                    <AuthRoute
                        exact
                        path={ authRoutes.orders }
                        requiredRoles={[
                            userRoles.admin,
                            userRoles.manager,
                            userRoles.user_agent,
                            userRoles.user
                        ]}
                        Component={
                            checkAccessForB2B
                            (
                                withAuthRedirect(OrdersContainer)
                            )
                        }
                    />
                    <AuthRoute
                        exact
                        path={ authRoutes.personalInfo }
                        requiredRoles={[
                            userRoles.admin,
                            userRoles.manager,
                            userRoles.user_agent,
                            userRoles.user
                        ]}
                        Component={
                            checkAccessForB2B
                            (
                                withAuthRedirect(
                                    withNavTabsForContractSettings(PersonalInfo)
                                )
                            )
                        }
                    />
                    <AuthRoute
                        exact
                        path={ authRoutes.partnerSittings }
                        requiredRoles={[
                            userRoles.admin,
                            userRoles.manager
                        ]}
                        Component={
                            checkAccessForB2B
                            (
                                withAuthRedirect(
                                    withNavTabsForContractSettings(PartnerSettings)
                                )
                            )
                        }
                    />
                    <AuthRoute
                        exact
                        path={ authRoutes.users }
                        requiredRoles={[
                            userRoles.admin,
                            userRoles.manager
                        ]}
                        Component={
                            checkAccessForB2B
                            (
                                withAuthRedirect(
                                    withNavTabsForContractSettings(Users)
                                )
                            )
                        }
                    />
                    <AuthRoute
                        exact
                        path={ authRoutes.companies }
                        requiredRoles={[
                            userRoles.admin
                        ]}
                        Component={
                            checkAccessForB2B
                            (
                                withAuthRedirect(
                                    withNavTabsForContractSettings(Companies)
                                )
                            )
                        }
                    />
                    <AuthRoute
                        exact
                        path={ authRoutes.passengers }
                        requiredRoles={[
                            userRoles.admin,
                            userRoles.manager,
                            userRoles.user_agent,
                            userRoles.user
                        ]}
                        Component={
                            checkAccessForB2B
                            (
                                withAuthRedirect(
                                    withNavTabsForContractSettings(Passengers)
                                )
                            )
                        }
                    />
                    <AuthRoute
                        exact
                        path={ authRoutes.main }
                        requiredRoles={[
                            userRoles.admin,
                            userRoles.manager,
                            userRoles.user_agent,
                            userRoles.user
                        ]}
                        Component={
                            withAuthRedirect(SearchContainer)
                        }
                    />
                    <AuthRoute
                        exact
                        path={ authRoutes.staticPage }
                        requiredRoles={[
                            userRoles.admin,
                            userRoles.manager,
                            userRoles.user_agent,
                            userRoles.user
                        ]}
                        Component={
                            withAuthRedirect(
                                staticPages(MainInfoPage)
                            )
                        }
                    />
                    <AuthRoute 
                        exact
                        path={ authRoutes.balanceMovements }
                        requiredRoles={[
                            userRoles.admin,
                            userRoles.manager
                        ]}
                        Component={
                            withAuthRedirect(BalanceMovements)
                        }
                    />
                    <AuthRoute
                        exact
                        path={authRoutes.support}
                        requiredRoles={[
                            userRoles.admin,
                            userRoles.manager,
                            userRoles.user_agent,
                            userRoles.user,
                        ]}
                        Component={withAuthRedirect(Support)}
                    />
                    <AuthRoute
                        exact
                        path={authRoutes.supportTicket}
                        requiredRoles={[
                            userRoles.admin,
                            userRoles.manager,
                            userRoles.user_agent,
                            userRoles.user,
                        ]}
                        Component={withAuthRedirect(SupportTicket)}
                    />
                    <Route
                        exact
                        path={ authRoutes.notFoundPage }
                        component={
                            withAuthRedirect(PageNotFound)
                        }
                    />
                </Switch>
            </Suspense>
        );
    }
}

export default Routes;



