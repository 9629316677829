import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import './index.css';
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import { Provider } from 'react-redux'
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from './styles/common/index';
import ErrorBoundary from './hoc/errorBoundary';
import { CustomThemeProvider } from './providers/ThemeProvider';
import LangProvider from './providers/LangProvider';
import AccessRoleProvider from './providers/AccessRoleProvider';
import SupportProvider from './providers/SupportProvider/provider';
import { SnackbarProvider } from 'notistack';

require('dotenv').config();
const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <GlobalStyle />
        <BrowserRouter>
            <SnackbarProvider>
                <ErrorBoundary>
                    <CustomThemeProvider>
                        <LangProvider>
                            <AccessRoleProvider>
                                <SupportProvider>
                                    <App/>
                                </SupportProvider>
                            </AccessRoleProvider>
                        </LangProvider>
                    </CustomThemeProvider>
                </ErrorBoundary>
            </SnackbarProvider>
        </BrowserRouter>
    </Provider>
    , document.getElementById('root'));

serviceWorker.unregister();
