const palette = {
  white: "#fff",              // 113
  burningOrange: "#ff7043",   // 81
  mineShaft: "#212121",       // 80
  porcelainLight: "#f2f4f5",  // 36
  geyser: "#cfd8dc",          // 34
  grayChateau: "#9da8ae",     // 31
  osloGray: "#7e878b",        // 31
  submarine: "#b9c1c5",       // 27
  black: "#000",              // 21
  linen: "#fbe9e7",           // 19
  porcelain: "#e4e7e8",       // 15
  athensGrey: "#fafbfc",      // 13
  tallPoppy: "#c62828",       // 12
  thunderbird: "#c63f17",     // 12
  flamingo: "#f05e30",        // 11
  fruitSalad: "#4caf50",      // 9
  romantic: "#ffccbc",        // 8
  buddhaGold: "#c49000",      // 6
  concrete: "#f2f2f2",        // 6
  doveGray: "#696969",        // 5
  silver: "#c6c6c6",          // 5
  alto: "#dbdbdb",            // 4
  bigStone: "#121c2f",        // 4
  monaLisa: "#ffab91",        // 4
  flamingoLight: "#f06236",   // 3
  ghost: "#ced4da",           // 3
  gullGray: "#90a4ae",        // 3
  orange: "#ff531d",          // 3
  red: "#ff0302",             // 3
  sandyBrown: "#f7a65e",      // 3
  steelBlue: "#4682B4",       // 3
  towerGray: "#b0bec5",       // 3
  tundora: "#444",            // 3
  cornflowerBlue: "#6495ed",  // 2
  desertStorm: "#eeeeec",     // 2
  gallery: "#ebebeb",         // 2
  gullGrayLight: "#a0b0b9",   // 2
  pastelGreen: "#67d457",     // 2
  punch: "#dc3545",           // 2
  redBerry: "#8b0000",        // 2
  whisper: "#fafafc",         // 2
  silverDark: "#b9b9b9",      // 1
  scooter: "#32abca",         // 1
  fruitSaladLight: "#5BA554", // 1
  fruitSaladDark: "#449d48",  // 1
  galleryLight: "#f0eeee",    // 1
  galleryDark: "#ccc",        // 1
  ironLight: "#e4e6e7",       // 1
  iron: "#d7d7d9",            // 1
  monza: "#d20836",           // 1
  goblin: "#3d8b40",          // 1
  goblinDark: "#388c3c",      // 1
  quillGray: "#e2e2e1",       // 1
  dustyGray: "#999",          // 1
  linkWater: "#e3f0f9",       // 1
  pictonBlueLight: "#42a5f5", // 1
  pictonBlue: "#2cb7f5",      // 1
  gold: "#ffd700",            // 1
  turkishRose: "#b87474",     // 1
  peach: "#ffe4b5",           // 1
  pigmentoIndigo: "#4b0082",  // 1
  seagull: "#87ceeb",         // 1
  steelBlueDark: "#4390ae",   // 1
  fountainBlue: "#45b0b0",    // 1
  deluge: "#6f5ba6",          // 1
  sunshade: "#ffa726",        // 1
  burntSienna: "#ef5350",     // 1
  jungleGreen: "#26a69a",     // 1
  chateauGreen: "#3eab50",    // 1
  alizarinCrimson: "#e81b1b", // 1
  apple: "#3fb544",           // 1
  chambray: "#3b5999",        // 1
  ceriseRed: "#e4405f",       // 1
  deepCerulian: "#0077b5",    // 1
  silverChalice: "#a9a9a9",   // 1
  solitude: "#eaf5ff",        // 1
};

export default palette;
