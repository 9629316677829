import React, { forwardRef, useCallback, useState } from "react";

import { Button, Card, CardActions, Collapse, IconButton, Paper, Typography, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SnackbarContent, useSnackbar } from "notistack";
import { useHistory } from 'react-router-dom';

import palette from "../../styles/palette";
import { useContext } from "react";
import { LangContext } from "../../providers/LangProvider";

const useStyles = makeStyles(() => ({
  root: {
    "@media (min-width:600px)": {
      minWidth: "344px !important"
    }
  },
  card: {
    backgroundColor: palette.chateauGreen,
    width: "100%"
  },
  typography: {
    fontWeight: "bold",
    color: palette.white,
  },
  actionRoot: {
    padding: "8px 8px 8px 16px",
    justifyContent: "space-between"
  },
  icons: {
    marginLeft: "auto"
  },
  expand: {
    padding: "8px 8px",
    transform: "rotate(0deg)",
    transition: "all .2s",
    color: palette.white,
  },
  collapse: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 8,
    padding: 16
  },
  buttonBlock: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  button: {
    padding: "4px 8px",
    textTransform: "none"
  },
  ticketId: {
    fontSize: 18,
    fontWeight: 500,
  },
  info: {
    fontSize: 14,

    "& > span": {
      fontWeight: 500,
    },
  },
  infoMessage: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: -4,
  }
}));

const SupportNotification = forwardRef((props, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const { t } = useContext(LangContext);

  const [expanded, setExpanded] = useState(false);

  const history = useHistory();

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  const handleGoToTicketPage = () => {
    history.push(`/support/${props.data.ticket.id}/${props.data.order.order_id}/${props.data.order.session_id}`);
    handleDismiss();
  };

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <Typography variant="subtitle2" className={classes.typography}>
            {props.message}
          </Typography>

          <div className={classes.icons}>
            <IconButton
              aria-label="Show more"
              className={classes.expand}
              style={expanded ? null : { transform: "rotate(180deg)" }}
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>

            <IconButton className={classes.expand} onClick={handleDismiss}>
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.collapse}>
            <div className={classes.ticketId}>{t.request} #{props.data.ticket.id}</div>
            <div className={classes.info}>{t.order}: <span>{props.data.order.order_id}</span></div>
            <div className={classes.info}>{t.from}: <span>{props.data.admin.name}</span></div>
            <div className={classes.info}>{t.message_text}: </div>
            <div className={classes.infoMessage}>{props.data.message.text}</div>

            <div className={classes.buttonBlock}>
              <Button size="small" variant="contained" className={classes.button} onClick={handleGoToTicketPage}>
                {t.go_to_request}
              </Button>
            </div>
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  );
});

export default SupportNotification;
