import React, { createContext, useEffect, useState } from 'react';
import { API } from '../lib/api';
import Config from '../config/config';
import MainLoadingContainer from '../containers/loadingForms/mainLoading';

const userRoles = Config.UserRoles();

export const AccessRoleContext = createContext({
    userRole: null,
    setUserRole: () => {}
});

function AccessRoleProvider (props) {
    const [userRole, setUserRole] = useState(userRoles.user);
    const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
        API.getUserData().then(response => {
            setUserRole(response.clientType);
            setIsLoad(true);
        }).catch(() => {
            setIsLoad(true);
        });
    }, []);

    if (!isLoad) {
        return <MainLoadingContainer/>;
    }

    return (
        <AccessRoleContext.Provider value={{
            userRole,
            setUserRole
        }}>
            { props.children }
        </AccessRoleContext.Provider>
    )
}

export default AccessRoleProvider;