import React, { useState, Fragment } from 'react';
import { compose } from 'redux';
import onClickOutside from 'react-onclickoutside';
import MobileHeaderMenu from  './mobileHeaderMenu';
import DesktopHeaderMenu from  './desktopHeaderMenu';
import B2cHeaderMenu from  './b2cHeaderMenu';
import B2cMobileHeaderMenu from  './b2cMobileHeaderMenu';
import config from '../../config/config';
import windowSize from '../../hoc/windowSize';
import { useStyles } from '../../containers/common/header/styles';

const { isB2c } = config;
const authRoutes = config.AuthRoutes();
const webConfigs = config.getWebConfig();

function HeaderMenu(props) {
    return RenderHeader(props);
}

const clickOutsideConfig = {
    handleClickOutside: () => HeaderMenu.handleClickOutside
};

const RenderHeader = (props) => {
    const classes = useStyles();
    const { agentsUrl } = webConfigs;
    const { headerWithoutImg, withHeader, setIsAuth, clientData, history, windowHeight,
        searchOrder, logout, doNotFoundOrder, clearSearchOrderFailResult, showOrdersInHeader,
        withLogo, hideHeader, partnerLogo, userData } = props;
    const { clientName, partnerName, otb, ownFunds, creditLine, partnerCurrency } = clientData;
    const [showMenu, setShowMenu] = useState(false);
    const [showBalance, setShowBalance] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [openBalance, setOpenBalance] = useState(false);
    const [openConSettings, setOpenConSettings] = useState(false);
    let fullClientName = clientName + ' ('+ partnerName +')';

    const goToSearchPage = () => {
        history.push({
            pathname: '/',
        });
    };

    const goToAgentsPage = () => {
        window.location.href = agentsUrl;
    }

    const goToOrdersPage = () => {
        history.push({
            pathname: authRoutes.orders,
        });
    };

    if(fullClientName.length > 25) {
        fullClientName = fullClientName.slice(0, 25) + '...';
    }

    HeaderMenu.handleClickOutside = () => {
        setShowMenu(false);
    };

    if(!withHeader) {
        return null;
    }

    const handleClickStillLink = () => {
        setShowMenu(!showMenu);
    };

    const handleLogout = () => {
        logout();
        setShowMenu(false);
        setIsAuth(false);
    };

    const handleClickShowMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    };

    const b2cHeaderMenu = () => {
        return (
            <B2cHeaderMenu
                agentsUrl={ agentsUrl }
                goToAgentsPage={ goToAgentsPage }
                headerWithoutImg={ headerWithoutImg }
                searchOrder={ searchOrder }
                doNotFoundOrder={ doNotFoundOrder }
                clearSearchOrderFailResult={ clearSearchOrderFailResult }
                withLogo={ withLogo }
                partnerLogo={ partnerLogo }
                history={ history }
            />
        );
    };

    const b2cMobileHeaderMenu = () => {
        return (
            <B2cMobileHeaderMenu
                agentsUrl={ agentsUrl }
                goToAgentsPage={ goToAgentsPage }
                headerWithoutImg={ headerWithoutImg }
                searchOrder={ searchOrder }
                doNotFoundOrder={ doNotFoundOrder }
                clearSearchOrderFailResult={ clearSearchOrderFailResult }
                handleClickShowMenu={ handleClickShowMenu }
                setShowMobileMenu={ setShowMobileMenu }
                showMobileMenu={ showMobileMenu }
                heightSize={ windowHeight }
                goToSearchPage={ goToSearchPage }
                withLogo={ withLogo }
                partnerLogo={ partnerLogo }
                history={ history }
                setShowMenu={ setShowMenu }
                showMenu={ showMenu }
            />
        );
    };

    const desktopHeaderMenu = () => {
        return (
            <DesktopHeaderMenu
                headerWithoutImg={ headerWithoutImg }
                fullClientName={ fullClientName }
                otb={ otb }
                partnerCurrency={ partnerCurrency }
                setShowMenu={ setShowMenu }
                showMenu={ showMenu }
                handleLogout={ handleLogout }
                setShowBalance={ setShowBalance }
                showBalance={ showBalance }
                ownFunds={ ownFunds }
                creditLine={ creditLine }
                handleClickStillLink={ handleClickStillLink }
                goToSearchPage={ goToSearchPage }
                goToOrdersPage={ goToOrdersPage }
                showOrdersInHeader={ showOrdersInHeader }
                withLogo={ withLogo }
                partnerLogo={ partnerLogo }
                history={ history }
                clientData={ clientData }
                userData={ userData }
            />
        );
    };

    const mobileHeaderMenu = () => {
        return (
            <MobileHeaderMenu
                headerWithoutImg={ headerWithoutImg }
                handleClickShowMenu={ handleClickShowMenu }
                setShowMobileMenu={ setShowMobileMenu }
                showMobileMenu={ showMobileMenu }
                heightSize={ windowHeight }
                fullClientName={ fullClientName }
                otb={ otb }
                partnerCurrency={ partnerCurrency }
                setShowMenu={ setShowMenu }
                showMenu={ showMenu }
                handleLogout={ handleLogout }
                history={ history }
                goToSearchPage={ goToSearchPage }
                withLogo={ withLogo }
                partnerLogo={ partnerLogo }
                userData={ userData }
                clientData={ clientData }
                ownFunds={ ownFunds }
                openBalance={ openBalance }
                setOpenBalance={ setOpenBalance }
                openConSettings={ openConSettings }
                setOpenConSettings={ setOpenConSettings }
            />
        );
    };

    const getContainerClasses = type => {
        let classNames = [];
        if(type === 'desktop') {
            classNames.push(classes.desktopMenuContainer);
        } else {
            classNames.push(classes.mobileMenuContainer);
        }
        if(hideHeader) {
            classNames.push(classes.menuContainerHidden);
        }
        return classNames.join(' ');
    }

    if(isB2c) {
        return (
            <Fragment>
                <div className={ getContainerClasses('desktop') }>
                    { b2cHeaderMenu() }
                </div>
                <div className={ getContainerClasses('mobile') }>
                    { b2cMobileHeaderMenu() }
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={ getContainerClasses('desktop') }>
                { desktopHeaderMenu() }
            </div>
            <div className={ getContainerClasses('mobile') }>
                { mobileHeaderMenu() }
            </div>
        </Fragment>
    )
};

export default compose(
    windowSize,
    onClickOutside
)(HeaderMenu, clickOutsideConfig);