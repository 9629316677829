import styled from 'styled-components'
import palette from '../../palette';

export const HeaderContainer = styled.div`
    text-align: center;
    width: 100%;
    color: ${palette.white};

    ${props => props.headerWithoutImg && `
        background-color: ${palette.bigStone};
    `}

    @media screen and (max-width: 960px) {
        background-color: ${palette.bigStone};
    }
`;