import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { isB2c } from '../../../../config/config';
import { LangContext } from '../../../../providers/LangProvider';
import palette from '../../../../styles/palette';

const useStyles = makeStyles({
    linkContainerBackTo: {
        cursor: 'pointer',
        color: palette.osloGray,
        fontSize: '16px'
    },
    linkBackButton: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    linkTextBackTo: {
        padding: '16px 0'
    },
    linkBackIcon: {
        width: '16px',
        paddingRight: '8px',
        display: 'flex',
        alignItems: 'center'
    }
});

export default function LinkBackTo(props) {
    const classes = useStyles();
    const { t } = useContext( LangContext );
    const { onClick, title } = props;

    const titleLink = title ? title : t.back_to_orders;

    return(
        <Box className={ classes.linkContainerBackTo }>
            { !isB2c &&
                <Box className={ classes.linkBackButton } onClick={ () => onClick() }>
                    <Box className={ classes.linkBackIcon }>
                        <ArrowBackIosIcon fontSize='small' />
                    </Box>
                    <Box className={ classes.linkTextBackTo }>
                        { titleLink }
                    </Box>
                </Box>
            }
        </Box>
    );
}

LinkBackTo.defaultProps = {
    title: null
};
