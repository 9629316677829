import React from "react";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import palette from "../../../../styles/palette";

const theme = createMuiTheme({
    overrides: {
        MuiInputLabel: {
            root: {
                color: palette.grayChateau,
                paddingLeft: 8,

                "&$focused": {
                    color: palette.grayChateau
                }
            },
            formControl: {
                width: '120%'
            }
        }
    }
});

const useStylesInput = makeStyles({
    root: {
        border: `1px solid ${palette.quillGray}`,
        overflow: 'hidden',
        borderRadius: 4,
        paddingLeft: 8,
        backgroundColor: palette.white,
        color: palette.mineShaft,
        fontSize: 18,

        '&:hover': {
            backgroundColor: palette.white,
        }
    },
    focused: {
        backgroundColor: palette.white,
        borderColor: palette.burningOrange,
    }
});

function InputTextField(props) {
    const classes = useStylesInput();
    const { InputProps, input } = props;

    return <TextField
        { ...props }
        { ...input }
        autoComplete='off'
        InputProps={{
            ...InputProps,
            classes,
            disableUnderline: true
        }}
    />;
}

export default function InputUI(props) {
    const { onChange } = props;

    const handelChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <ThemeProvider theme={ theme }>
            <InputTextField
                { ...props }
                onChange={ handelChange }
                variant='filled'
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}

            />
        </ThemeProvider>
    );
}

InputUI.defaultProps = {
    onChange: () => {}
};