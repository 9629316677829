import { makeStyles } from '@material-ui/core/styles';
import palette from '../../styles/palette';

export const useStyles = makeStyles({
    rootContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    form: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    authBlock: {
        width: '100%',
        maxWidth: '360px',
        borderRadius: '8px',
        boxShadow: '0 4px 40px 0 rgba(0, 0, 0, 0.08)',
        backgroundColor: palette.white,
        position: 'relative',
        padding: '50px 20px',
        boxSizing: 'border-box',
        margin: '20px',

        '@media (min-width:576px)': {
            maxWidth: '560px',
        },
    },
    activationBlock: {
        width: '100%',
        maxWidth: '360px',
        borderRadius: '8px',
        boxShadow: '0 4px 40px 0 rgba(0, 0, 0, 0.08)',
        backgroundColor: palette.white,
        position: 'relative',
        padding: '50px 20px',
        boxSizing: 'border-box',
        margin: '20px',

        '@media (min-width:576px)': {
            maxWidth: '560px',
        },
    },
    activationErrorBlock: {
        width: '304px',
        height: '236px',
        borderRadius: '8px',
        boxShadow: '0 4px 40px 0 rgba(0, 0, 0, 0.08)',
        backgroundColor: palette.white,

        '@media (min-width:576px)': {
            width: '400px',
            height: '270px'
        },
    },
    activationErrorSubmitBlock: {
        paddingTop: '24px'
    },
    loginTitleBlock: {
        width: '100%',
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: '500',
        color: palette.mineShaft,
        paddingBottom: '16px',

        '@media (min-width:576px)': {
            paddingTop: '16px',
        },
    },
    loginTitle: {
        margin: 'auto',

        '@media (min-width:576px)': {
            width: '100%'
        },
    },
    loginAgreementTextBlock: {
        paddingTop: '12px',
        
        '@media (min-width:576px)': {
            paddingTop: '20px'
        },
    },
    loginAgreementText: {
        fontSize: '12px',
        color: palette.gullGray,
        margin: 'auto',

        '@media (min-width:576px)': {
            width: '380px'
        },
    },
    buttonBlock: {
        paddingTop: '10px'
    },
    inputBlock: {
        height: '87px'
    },
    linksBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 'auto',

        '@media (min-width:576px)': {
            width: '380px'
        },
    },
    inputItem: {
        margin: 'auto',
        maxWidth: '320px',

        '@media (min-width:576px)': {
            maxWidth: '380px'
        },
    },
    activationErrorMess: {
        fontSize: '18px',
        color: palette.grayChateau,
        width: '100%'
    },
    langBLock: {
        position: 'absolute',
        bottom: '2%',
        right: '3%'
    }
});