import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import palette from "../../../../styles/palette";

export default function TooltipUI(props) {
    const { title, children } = props;

    if(_.isNull(title)){
        return <Box>
            { children }
        </Box>;
    }

    return (
        <LightTooltip title={title} placement="top" TransitionComponent={Zoom}>
            { children }
        </LightTooltip>
    );
}

const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: palette.white,
        color: palette.mineShaft,
        boxShadow: theme.shadows[1],
        fontSize: 11,
        marginBottom: '5px',
        textAlign: 'center'
    },
}))(Tooltip);

TooltipUI.defaultProps = {
    title: null
};