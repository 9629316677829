import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../../styles/palette';

const useStyles = makeStyles({
    checked: {
        color: `${palette.burningOrange} !important`,
    }
});

export default function CheckboxUI(props) {
    const classes = useStyles();

    return <Checkbox
        classes={{ checked: classes.checked }}
        { ...props }
    />;
}