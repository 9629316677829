import React from 'react';
import {StyledCalendarInput} from "../../styles/search/index";
import Calendar from "../ui/components/Calendar/Calendar";
import { serverDateFormat, DateFormatWithoutPoint } from "../../config/config";
import {
    CalendarArrowDown,
    StyledCalendarFieldButtonBlock,
    StyledRemoveButton
} from "../../styles/common/ui/calendar/index";
import moment from 'moment';
import { getCustomSize } from "../../lib/getCustomSize";
import { parserLangCode } from '../result/common';
import palette from '../../styles/palette';

function calendarField (props) {
    const {
        onUpdate,
        name,
        type,
        meta: { touched, error },
        disabled,
        input,
        fieldError,
        placeholder,
        directionType,
        changeDirection,
        selectedDates,
        activeDateIndex,
        removeCalendarValue,
        windowWidth,
        showCalendar,
        setShowCalendar,
        language,
        refData
    } = props;

    const handleClickCalendarField = () => {
        setShowCalendar(!showCalendar);
    }

    const handelOnUpdate = (e) => {
        hideCalendar();
        onUpdate(e);
    }

    const hideCalendar = () => {
        setShowCalendar(false);
    }

    const getFormattedValue = (value, withWeekDayForMobile) => {
        const format = withWeekDayForMobile ? serverDateFormat : DateFormatWithoutPoint;

        if (value) {
            return moment
                .unix(value)
                .locale(parserLangCode(language))
                .format(format)
                .replace('.', '');
        }

        return '';
    }

    const isMobile = getCustomSize.isMobile(windowWidth);
    const withWeekDayForMobile = getCustomSize.withWeekDayForMobile(windowWidth);
    const formattedValue = getFormattedValue(input.value, withWeekDayForMobile);

    return(
        <div className='CalendarFieldBlock'>
            <StyledCalendarInput
                { ...input }
                value={ formattedValue }
                readOnly={ true }
                type={ type }
                name={ name }
                disabled={ disabled }
                placeholder={ placeholder }
                onClick={ handleClickCalendarField}
                outsideClickIgnoreClass={ 'CalendarFieldBlock' }
                borderColor={ fieldError || (touched && error) ? palette.tallPoppy : '' }
                isFocus={ showCalendar }
            />

            <StyledCalendarFieldButtonBlock>
                { input.value ?
                    <StyledRemoveButton
                        onClick={ removeCalendarValue }
                        type='button'
                        isMobile={ isMobile }
                    /> :
                    <CalendarArrowDown
                        onClick={ handleClickCalendarField }
                    />
                }
            </StyledCalendarFieldButtonBlock>

            { showCalendar &&
                <div ref={ refData }>
                    <Calendar
                        onSelect={ handelOnUpdate }
                        directionType={ directionType }
                        changeDirection={ changeDirection }
                        selectedDates={ selectedDates }
                        activeDateIndex={ activeDateIndex }
                    />
                </div>
            }
        </div>
    );
}

calendarField.defaultProps = {
    onUpdate: ()=> {}
};

export default calendarField;