import React, {useContext} from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { LangContext } from '../../../../providers/LangProvider';
import palette from '../../../../styles/palette';

const useStyles = makeStyles({
    helpTitle: {
        fontSize: '24px',
        fontWeight: '500',
        color: palette.black,
    },
    helpItem: {
        color: palette.mineShaft,
        paddingTop: '8px',
        fontSize: '16px',
        cursor: 'pointer'
    },
    helpLink: {
        color: palette.burningOrange,
        fontSize: '16px',
        cursor: 'pointer'
    },
    helpArrow: {
        paddingLeft: '2px',
        position: 'relative',
        top: '8px',
        cursor: 'pointer'
    }
});

export default function HelpBlock() {
    const classes = useStyles();
    const { t } = useContext( LangContext );

    if (true) { // Отключаем отображение до соответвующих доработок на беке
        return null;
    }

    return (
        <Box className={ classes.helpBlock }>
            <Box className={ classes.helpTitle }>{ t.help }</Box>
            <Box className={ classes.helpItem }>{ t.exchange_and_return_rules }</Box>
            <Box className={ classes.helpItem }>{ t.after_flight }</Box>
            <Box className={ classes.helpLink }>
                <Box component='span'>{ t.go_to_section }</Box>
                <Box component='span' className={ classes.helpArrow }>
                    <ArrowRightAltIcon />
                </Box>
            </Box>
        </Box>
    );
};