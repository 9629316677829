import styled from 'styled-components'
import bgImage from '../../images/bg-image.png';
import {StyledButton, StyledLink} from "../common/index";
import palette from '../palette';

export const LinkText = styled(StyledLink)`
    color: ${palette.mineShaft};
`;

export const LinkTextLeft = styled(LinkText)`
    float: left;
`;

export const LinkTextRight = styled(LinkText)`
    float: right;
`;

export const StyledLoginButton = styled(StyledButton)`
    width: 256px;
    height: 56px;
    font-size: 20px;
    
    @media (min-width:576px) {
        width: 380px;
        height: 64px;
        font-size: 24px;
    }
`;

export const StyledErrorActivationButton = styled(StyledButton)`
    width: 236px;
    height: 48px;
    font-size: 16px;
    
    @media (min-width:576px) {
        width: 300px;
        height: 44px;
        font-size: 18px;
    }
`;

export const StyledAuthContainer = styled.div`
    background-image: url(${ bgImage });
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;