import styled from 'styled-components'
import active from '../../../../images/checkbox/checkbox-active.png';
import inactive from '../../../../images/checkbox/checkbox-inactive.png';
import palette from '../../../palette';

export const CheckboxItem = styled.button`
    height: 24px;
    width: 24px;
    background-color: ${props => props.backgroundColor || palette.porcelainLight};
    background-image: url(${props => props.isActive ? active : inactive});
    background-repeat: no-repeat;
    background-size: 24px;
    background-position:  center center;
    border: none;
    cursor: pointer;
    
    :focus { 
        outline: none; 
    }
`;
