import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelectUI from '../NativeSelectUI/NativeSelectUI';
import device from 'current-device';
import palette from '../../../../styles/palette';

function SelectTransparentUI (props) {
    const {
        onChange,
        input,
        items,
        value,
        color,
        fontSize,
        label
    } = props;

    const useStyles = makeStyles({
        root: {
            overflow: 'hidden',
            backgroundColor: 'transparent',
            color: color ? color : palette.white,
            fontSize: fontSize ? fontSize : 16
        },
        select: {
            '&:focus': {
                backgroundColor: 'transparent'
            },
            '&:hover': {
                backgroundColor: 'transparent'
            },
            '& option': {
                color: device.type === 'mobile' ? palette.black : 'inherit'
            }
        },
        icon: {
            color: color ? color : palette.white,
        }
    });

    const classes = useStyles();

    const handelOnChange = (event) => {
        const value = event.target.value;
        onChange(value);
    };

    const handleNativeChange = value => {
        onChange(value);
    };

    const desktopSelect = () => (
        <Select
            value={ value }
            onChange={ handelOnChange }
            input={ <Input {...input}/> }
            disableUnderline={ true }
            inputProps={{
                classes
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                },
                getContentAnchorEl: null
            }}
        >
            { items.map(({ label, value }) => (
                    <MenuItem
                        key={ value }
                        value={ value }
                    >
                        { label }
                    </MenuItem>
                )) }
        </Select>
    );

    return (
        <Fragment>
            { label &&
                <InputLabel style={{
                    fontSize: '12px',
                    color: palette.grayChateau,
                }}>
                    { label }
                </InputLabel> }
            { device.type === 'desktop' ?
                desktopSelect()
                :
                <NativeSelectUI
                    value={ value }
                    onChange={ handleNativeChange }
                    disableUnderline={ true }
                    styles={ classes }
                    items={ items }
                    color={ color }
                /> }
        </Fragment>
    );
}

export default SelectTransparentUI;
