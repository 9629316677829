import { makeStyles } from '@material-ui/core/styles';
import bgImage from '../../../images/bg-image.png';
import palette from '../../../styles/palette';

export const useStyles = makeStyles( theme => ({
    menuBlock: {
        fontSize: '14px',
        padding: '10px 0 10px 32px',
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    },
    menuLabel: {
        display: 'inline-table',
        textAlign: 'left',
        cursor: 'pointer'
    },
    menuContainer: {
        color: palette.black,
        padding: 0,
        fontSize: '17px',
        backgroundColor: palette.white,
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.08)',
        borderRadius: '4px',
        width: '280px',
        textAlign: 'left',
        position: 'absolute',
        right: -20,
        top: '100%',
        zIndex: '1'
    },
    menuItem: {
        padding: '10px',
    },
    link: {
        color: palette.black,
        display: 'block'
    },
    menuBalanceItem: {
        padding: '4px 0 4px 8px',
    },
    linkItem: {
        color: palette.black,
        display: 'block',
        height: '25px',
    },
    linkItemFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: palette.black,
        height: '25px',
        paddingRight: 7,
    },
    unreadMessagesBadge: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: palette.white,
        backgroundColor: palette.burningOrange,
        borderRadius: '50%',
        fontSize: 10,
        width: 20,
        height: 20,
    },
    item: {
        paddingTop: '3px',
        paddingLeft: '7px',
        display: 'block'
    },
    b2cHeaderMenuItem: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '18px',
        lineHeight: 1.1,
        padding: '23px 0 23px 32px',
        cursor: 'pointer'
    },
    agents: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '18px',
        lineHeight: 1.1,
        cursor: 'pointer'
    },
    agentAndSearchButtons: {
        display: 'flex'
    },
    headerMenuItem: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '18px',
        lineHeight: 1,
        padding: '18px 0 18px 32px',
        cursor: 'pointer'
    },
    balanceTitle: {
        fontSize: '14px'
    },
    balanceMobile: {
        height: '35px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        cursor: 'pointer',
        paddingLeft: '16px',
        '&:hover': {
            background: palette.linen
        },
        fontSize: '15px',
    },
    expandMobile: {
        fontSize: '15px'
    },
    balance: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        cursor: 'pointer',
        padding: '16px',
        boxSizing: 'border-box',
        '&:hover': {
            background: palette.linen
        }
    },
    balanceContainer: {
        color: palette.black,
        fontSize: '12px',
        backgroundColor: palette.white,
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.08)',
        borderRadius: '4px',
        width: '245px',
        textAlign: 'left',
        position: 'absolute',
        top: '100%',
        zIndex: '1'
    },
    menuDropdown: {
        color: palette.black,
        fontSize: '16px',
        backgroundColor: palette.white,
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.08)',
        width: '280px',
        textAlign: 'left',
        position: 'absolute',
        right: '0px',
    },
    closeMenuIconBlock: {
        width: '100%',
        textAlign: 'right',
        cursor: 'pointer'
    },
    closeMenuIcon: {
        paddingRight: '8px',
        paddingTop: '8px',
        width: '1.2em',
        height: '1.2em'
    },
    mobileMenuBalance: {
        width: '100%',
        padding: '5px',
        paddingLeft: theme.spacing(3.5),
        fontSize: '12px'
    },
    mobileMenuClientName: {
        fontSize: '18px',
        width: '100%',
        padding: '4px 16px',
        fontWeight: '500',
        boxSizing: 'border-box'
    },
    mobileMenuItem: {
        width: '100%',
        cursor: 'pointer',
        padding: '16px',
        boxSizing: 'border-box',
        '&:hover': {
            background: palette.linen
        }
    },
    agency: {
        width: '100%',
        cursor: 'pointer',
        paddingLeft: '16px',
        boxSizing: 'border-box',
        '&:hover': {
            background: palette.linen
        }
    },
    linkToMainPage: {
        display: 'block',
        color: palette.black
    },
    line: {
        width: '100%',
        height: '2px',
        backgroundColor: palette.desertStorm,
    },
    smallLineBlock: {
        width: '50px'
    },
    fullLineBlock: {
        width: '100%'
    },
    backdrop: {
        zIndex: 2,
        color: palette.white,
    },
    mobileMenuContainer: {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 50,
        width: '100%',
        display: 'block',
        transition: 'transform .3s ease',

        '@media (min-width:768px)': {
            display: 'none'
        }
    },
    desktopMenuContainer: {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 50,
        width: '100%',
        display: 'none',
        transition: 'transform .3s ease',
        background: `url(${bgImage})`,

        '@media (min-width:768px)': {
            display: 'block'
        }
    },
    menuContainerHidden: {
        transform: 'translateY(-65px)',
        transition: 'transform .3s ease'
    },
    textStart: {
        lineHeight: 1,
    },
    logoBlock: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    },
    clientCode: {
        textTransform: 'lowercase',
        fontSize: '14px',
        paddingLeft: '5px',
        color: palette.doveGray,
    },
    client: {
        display: 'flex',
        flexWrap: 'wrap',
        textTransform: 'lowercase',
        fontSize: '13px',
        color: palette.doveGray,
    },
    cCode: {
        marginRight: '5px'
    },
    clientType: {
        fontSize: '13px',
        color: palette.doveGray,
    },
    clientName: {
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
        marginTop: '2px'
    },
    clientBlock: {
        marginLeft: '10px'
    },
    partnerName: {
        height: '50px',
        borderTop: '1px solid darkgrey',
        borderBottom: '1px solid darkgrey',
    },
    partnerBlock: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '5px'
    },
    partnerMobileBlock: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '13px'
    },
    accountCircleBlock: {
        height: 45,
        width: 45,
        position: 'relative',
    },
    circleIcon: {
        width: '45px',
        height: '45px'
    },
    accountCircleBadge: {
        display: 'block',
        width: 12,
        height: 12,
        backgroundColor: palette.burningOrange,
        borderRadius: '50%',
        position: 'absolute',
        right: 2,
        top: 2,
    },
    buildingIcon: {
        height: '35px',
        width: '35px',
        marginTop: '7px'
    },
    root: {
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    collapse: {
        padding: '0 0 10px 0'
    },
    nestedMobile: {
        paddingTop: '7px',
        display: 'block',
        paddingLeft: theme.spacing(3.5),
        height: '30px',
        color: palette.doveGray,
        fontSize: '15px',
        '&:hover':{
            background: palette.linen,
            borderRadius: '4px'
        }
    },
    nested: {
        paddingTop: '7px',
        display: 'block',
        paddingLeft: theme.spacing(1.5),
        height: '25px',
        color: palette.doveGray,
        fontSize: '15px',
        '&:hover':{
            background: palette.linen,
            borderRadius: '4px'
        }
    },
    triangle: {
        float: 'right',
        marginTop: '-8px',
        marginRight: '32px',
        width: '20px',
        height: '8px',
        backgroundColor: palette.white,
        clipPath: 'polygon(50% 0, 0 100%, 100% 100%)'
    },
    item1: {
        marginTop: '5px',
        height: '30px',
        padding: '5px 0 0 7px',
        '&:hover': {
            background: palette.linen,
            borderRadius: '4px'
        }
    },
    logout: {
        height: '30px',
        padding: '5px 0 0 7px',
        '&:hover': {
            background: palette.linen,
            borderRadius: '4px'
        }
    }
}));