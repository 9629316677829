import React, { useContext } from 'react';
import { LangContext } from '../../../providers/LangProvider';
import SelectTransparentUI from '../../ui/components/SelectTransparentUI/SelectTransparentUI';
import { LANGUAGES_ITEMS } from '../../../containers/common/footer/constants';
import palette from '../../../styles/palette';

export default function SelectLanguage(props) {
    const { switchLang, language, t } = useContext(LangContext);
    const { withoutTitle, fontSize } = props;

    return <SelectTransparentUI
        items={ LANGUAGES_ITEMS }
        value={ language }
        label={ withoutTitle ? '' : t.language }
        onChange={ switchLang }
        color={palette.mineShaft}
        fontSize={ fontSize ? fontSize : '18px' }
    />
}