import React, { useContext, useState } from 'react';
import { Divider, Backdrop, Slide, Container, Grid } from '@material-ui/core';
import Link from '../ui/components/Link/Link';
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import * as Styled from '../../styles/common/headerMenu/index';
import { StyledHeaderMenuButton } from '../../styles/common/index';
import { useStyles } from '../../containers/common/header/styles';
import { LangContext } from '../../providers/LangProvider';
import { AccessRoleContext } from '../../providers/AccessRoleProvider';
import Config from '../../config/config';
import BusinessIcon from '@material-ui/icons/Business';
import { hasAdminManagerRoles, hasAdminRole } from '../result/common';
import MainLogo from './mainLogo';
import { normalizePrice } from '../../lib/normalize';
import useSupport from '../../providers/SupportProvider/hook';

const authRoutes = Config.AuthRoutes();

function MobileHeaderMenu(props) {
    const classes = useStyles();
    const { t } = useContext(LangContext);
    const { userRole } = useContext(AccessRoleContext);
    const [openFinances, setOpenFinances] = useState(false);

    const history = useHistory();

    const { unreadMessages } = useSupport();

    const {
        headerWithoutImg,
        handleClickShowMenu,
        setShowMobileMenu,
        showMobileMenu,
        heightSize,
        otb,
        partnerCurrency,
        ownFunds,
        creditLine,
        handleLogout,
        clientData,
        userData,
        openBalance,
        setOpenBalance,
        openConSettings,
        setOpenConSettings
    } = props;

    const handleClickToOpenBalance = () => (
        setOpenBalance(!openBalance)
    )
    const handleClickToOpenConSettings = () => (
        setOpenConSettings(!openConSettings)
    )
    const handleCLickToOpenFinances = () => {
        setOpenFinances(!openFinances)
    }

    const goToSupportOrdersPage = () => {
        setShowMobileMenu(false)
        history.push('/support');
    };

    return (
        <Styled.HeaderContainer headerWithoutImg={ headerWithoutImg }>
            <Container fixed>
                <Grid container justify={ 'space-between' }>
                    <Grid item className={ classes.logoBlock }>
                        <MainLogo { ...props }/>
                    </Grid>
                    <StyledHeaderMenuButton onClick={ handleClickShowMenu }/>
                </Grid>
                <Backdrop
                    className={ classes.backdrop }
                    open={ showMobileMenu }
                >
                    <Slide
                        direction='left'
                        in={ showMobileMenu }
                        mountOnEnter
                        unmountOnExit
                    >
                        <div
                            className={ classes.menuDropdown}
                            style={{ height: `${heightSize}px` }}
                        >
                            <div
                                className={ classes.closeMenuIconBlock }
                                onClick={ handleClickShowMenu }
                            >
                                <CloseIcon className={ classes.closeMenuIcon } />
                            </div>
                            <div className={ classes.mobileMenuClientName }>
                                { t.your_name }: { clientData.clientName }
                                <div className={ classes.client }>
                                    <span className={ classes.cCode }>{ `(${ t.code }:${ userData.clientCode })` }</span>
                                    <span>{ userData.clientMail }</span>
                                </div>
                                <div className={ classes.clientType }>
                                    { t[`${ userData.clientType }_role`]}
                                </div>
                            </div>
                            <div>
                                <ListItem
                                    className={ classes.balanceMobile } 
                                    onClick={ handleClickToOpenBalance }>
                                        { t.balance }
                                        { openBalance 
                                            ? <ExpandLess className={ classes.expandMobile }/> 
                                            : <ExpandMore className={ classes.expandMobile }/> 
                                        }
                                </ListItem>
                                <Collapse in={ openBalance }>
                                    <div className={ classes.mobileMenuBalance }>
                                        <div>
                                            { t.own_funds }: { normalizePrice(ownFunds) } { partnerCurrency }
                                        </div>
                                        <div className={ classes.smallLineBlock }>
                                            <Divider className={classes.line}/>
                                        </div>
                                        <div>
                                            { t.credit_line }: { normalizePrice(creditLine) } { partnerCurrency }
                                        </div>
                                        <div className={ classes.smallLineBlock }>
                                            <Divider className={classes.line}/>
                                        </div>
                                        <div>
                                            { t.otb }: { normalizePrice(otb) } { partnerCurrency }
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                            <div className={ classes.fullLineBlock }>
                                <Divider className={classes.line}/>
                            </div>
                            <div className={ classes.partnerMobileBlock }>
                                <div>
                                    <BusinessIcon className={ classes.buildingIcon }/>
                                </div>
                                <div className={ classes.clientBlock }>
                                    <div className={ classes.clientName }>
                                        { clientData.partnerName }
                                    </div>
                                    <div className={ classes.client }>
                                        { t.code }{ userData.partnerCode }
                                   </div>
                                </div>
                            </div>
                            <div className={ classes.fullLineBlock }>
                                <Divider className={ classes.line }/>
                            </div>
                            <div className={ classes.mobileMenuItem }>
                            <Link 
                                onClick={ () => setShowMobileMenu(false) }
                                className={ classes.linkToMainPage }
                                text={ t.to_main }
                                to={ authRoutes.goToSearchPage }
                            />
                            </div>
                            <div className={ classes.fullLineBlock }>
                                <Divider className={ classes.line }/>
                            </div>
                            <div className={ classes.mobileMenuItem }>
                                <Link 
                                    onClick={ () => setShowMobileMenu(false) }
                                    className={ classes.link }
                                    text={ t.orders }
                                    to={ authRoutes.orders }
                                />
                            </div>
                            <div className={ classes.fullLineBlock }>
                                <Divider className={classes.line}/>
                            </div>
                            <div>
                                <ListItem
                                    className={ classes.balance }
                                    onClick={ handleClickToOpenConSettings }
                                >
                                    { t.contract_settings }
                                    { openConSettings 
                                        ? <ExpandLess /> 
                                        : <ExpandMore /> 
                                    }
                                </ListItem>
                                <Collapse in={ openConSettings }>
                                    <div className={ classes.collapse }>
                                        {
                                            hasAdminManagerRoles(userRole) && 
                                                <Link
                                                    onClick={ () => setShowMobileMenu(false) }
                                                    className={ classes.nestedMobile }
                                                    text={ t.main }
                                                    to={ authRoutes.partnerSittings }
                                                />
                                        }
                                        <Link
                                            onClick={ () => setShowMobileMenu(false) }
                                            className={classes.nestedMobile}
                                            text={ t.your_profile }
                                            to={ authRoutes.personalInfo }
                                        />
                                        {
                                            hasAdminManagerRoles(userRole) && 
                                                <Link
                                                    onClick={ () => setShowMobileMenu(false) }
                                                    className={ classes.nestedMobile }
                                                    text={ t.users }
                                                    to={ authRoutes.users }
                                                />
                                        }
                                        <Link
                                            onClick={ () => setShowMobileMenu(false) }
                                            className={ classes.nestedMobile }
                                            text={ t.passenger_list }
                                            to={ authRoutes.passengers }
                                        />
                                        {
                                            hasAdminRole(userRole) && 
                                                <Link
                                                    onClick={ () => setShowMobileMenu(false) }
                                                    className={ classes.nestedMobile }
                                                    text={ t.affiliated_companies }
                                                    to={ authRoutes.companies }
                                                />
                                        }
                                    </div>
                                </Collapse>
                            </div>
                            {
                                hasAdminManagerRoles(userRole) &&
                                    <div>
                                        <ListItem
                                            className={ classes.balance }
                                            onClick={ handleCLickToOpenFinances }
                                        >
                                            { t.finances }
                                            { openFinances
                                                ? <ExpandLess /> 
                                                : <ExpandMore /> 
                                            }
                                        </ListItem>
                                        <Collapse in={ openFinances }>
                                            <div className={ classes.collapse }>
                                                <Link
                                                    onClick={ () => setShowMobileMenu(false) }
                                                    className={classes.nestedMobile}
                                                    text={ t.movement_balance }
                                                    to={ '/finances/balanceMovements' }
                                                />
                                            </div>
                                        </Collapse>
                                    </div>
                            }

                            <div className={ classes.mobileMenuItem }>
                                <div className={classes.linkItemFlex} onClick={goToSupportOrdersPage}>
                                    <span>Служба поддержки</span>
                                    {unreadMessages > 0 && (
                                        <span className={classes.unreadMessagesBadge}>{unreadMessages}</span>
                                    )}
                                </div>
                            </div>

                            <div className={ classes.fullLineBlock }>
                                <Divider className={classes.line}/>
                            </div>
                            
                            <div className={ classes.mobileMenuItem }>
                                <div onClick={ () => handleLogout() }>
                                    { t.log_out }
                                </div>
                            </div>
                        </div>
                    </Slide>
                </Backdrop>
            </Container>
        </Styled.HeaderContainer>
    );
}

export default MobileHeaderMenu;