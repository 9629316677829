import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import palette from '../../../../styles/palette';

const useStyles = makeStyles({
    buttonLoading: {
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '16%',
        left: '40%'
    }
});

function ButtonUI (props) {
    const classes = useStyles();
    const { title, fontSize, height, isGreen, endIcon, onClick, disabled, isLoading } = props;

    const CustomButton = withStyles({
        root: {
            height: height ? height : '40px',
            borderRadius: '4px',
            color: palette.white,
            cursor: 'pointer',
            fontSize: fontSize ? fontSize : '16px',
            fontWeight: 500,
            textTransform: 'none',
            backgroundColor: isGreen ? palette.fruitSalad : palette.burningOrange,

            '&:hover': {
                backgroundColor: isGreen ? palette.fruitSaladDark : palette.flamingo,
            },

            '&:active': {
                backgroundColor: isGreen ? palette.goblin : palette.thunderbird,
            },

            '&:focus': {
                outline: 'none'
            },

            '&:disabled': {
                backgroundColor: palette.geyser,
                color: palette.white,
                cursor: 'default',
                borderColor: palette.porcelainLight,
            }
        },
    })(Button);

    return (
        <Box className={ classes.buttonLoading }>
            <CustomButton
                variant='contained'
                disableRipple
                onClick={ onClick }
                endIcon={ endIcon }
                disabled={ disabled }
            >
                { title }
            </CustomButton>
            { isLoading &&
                <CircularProgress
                    style={{ color: palette.burningOrange }}
                    size={ 24 }
                    className={ classes.buttonProgress }
                />
            }
        </Box>
    );
}

export default ButtonUI;